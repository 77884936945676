var isRTL = ($('html').attr('dir') == "rtl") ? true : false,
    winWidth = $(window).width(),
    winHeight = $(window).height();

var vh = window.innerHeight * 0.01;
document.documentElement.style.setProperty('--vh', `${vh}px`);

var endDate = "2021-12-02";
var toDate = moment.tz(endDate, "Asia/Dubai").countdown();
if (toDate.value > 0 && $('.js-counter').get(0)) {
    $('.js-counter').remove();
}

$(function () {
    browserDetect();
    countDownInit();
    scrollToTopClickBind();
    inPageLinkingInit();
    fisrtFoldMinHeight();
});

$(window).on('load', function () {
    // Do after Page ready
    doOnLoad();
});

$(window).on('resize orientationchange', function () {
    // Do on resize
    if (winWidth != $(window).width()) {
        secMinHeight();
        fisrtFoldMinHeight();
    }
    winWidth = $(window).width(),
        winHeight = $(window).height();
});

$(window).on('scroll', function () {
    //Do on Scroll
    // addFixedClass();
});

$(document).keyup(function (e) {
    if (e.keyCode == 27) {
        //Do on ESC press
    }
});

function doOnLoad() {
    //OnLoad Function
    ChangeToSvg();
    secMinHeight();
    mobileMenu();
    dropdownBtnCustom();
    document.querySelector('body').classList.add('loaded');
    setVideoTag();
    swiper();
    swiper2();
    swiper3();
    accordian();
    videoSlider();
}

function browserDetect() {
    navigator.sayswho = (function () {
        var ua = navigator.userAgent,
            tem,
            M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
        if (/trident/i.test(M[1])) {
            tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
            return 'IE ' + (tem[1] || '');
        }
        if (M[1] === 'Chrome') {
            tem = ua.match(/\b(OPR|Edge)\/(\d+)/);
            if (tem != null) return tem.slice(1).join('').replace('OPR', 'Opera');
        }
        M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
        if ((tem = ua.match(/version\/(\d+)/i)) != null) M.splice(1, 1, tem[1]);
        return M.join(' ');
    })();
    $('body').addClass(navigator.sayswho);
}

var players = [];

function jsVideo() {
    // Custom player
    if ($('.js-video').length) {
        $('.js-video').each(function (i) {
            var thisParent = $(this).parent();
            players[i] = new Plyr(this, {
                playsinline: true,
            });
            thisParent.find('.plyr').attr('data-video-instance', i);
        });
    }
}

function jsVideoDirect() {
    if ($('.js-video').length) {
        $('.js-video').each(function (i) {
            $(this).attr('data-video-instance', i);
            var videoId = $(this).attr('data-plyr-embed-id');
            $(this).html('<iframe width="100%" height="100%" src="https://www.youtube.com/embed/' + videoId + '?rel=0&playsinline=1&enablejsapi=1" frameborder="0" allow="autoplay; encrypted-media" allowfullscreen></iframe>');
        });
    }
}
function intiMainSlider() {
    if ($(".js-main-slider").length) {
        $('.js-main-slider').slick({
            dots: false,
            arrows: false,
        });
    }
}
function secMinHeight() {
    $('.js-minHeight').each(function (i, e) {
        var $this = $(e),
            minHeight = $(window).outerHeight();
        $this.css('min-height', minHeight + "px");
    });
}
function fisrtFoldMinHeight() {
    var headerHeight = $('header').outerHeight();
    $('.js-minHeight-first-fold').each(function (i, e) {
        var $this = $(e),
            minHeightFirstFold = $(window).outerHeight();
        totalHeightFisrtFold = minHeightFirstFold - headerHeight
        $this.css('padding-top', headerHeight + "px");
        $this.css('height', minHeightFirstFold + 'px');
    });
}
function ChangeToSvg() {
    $('img.js-tosvg').each(function () {
        var $img = $(this);
        var imgID = $img.attr('id');
        var imgClass = $img.attr('class');
        var imgURL = $img.attr('src');
        $.get(imgURL, function (data) {
            var $svg = $(data).find('svg');
            if (typeof imgID !== 'undefined') {
                $svg = $svg.attr('id', imgID);
            }
            if (typeof imgClass !== 'undefined') {
                $svg = $svg.attr('class', imgClass + ' insvg');
            }
            $svg = $svg.removeAttr('xmlns:a');
            if (!$svg.attr('viewBox') && $svg.attr('height') && $svg.attr('width')) {
                $svg.attr('viewBox', '0 0 ' + $svg.attr('height') + ' ' + $svg.attr('width'))
            }
            $img.replaceWith($svg);
        }, 'xml');
    });
}
function mobileMenu() {
    $('.js-menu-toggle').click(function () {
        $('.header-menu-wraper').toggleClass('open')
        $("body").toggleClass("js-hiden-body")
        $('.header-logo').toggleClass('heder-logo-clr-change');
        $('.js-menu-toggle').toggleClass('active');
        setTimeout(function () {
            $('.menu-wraper').toggleClass('js-hid')
        }, 350);
    });
    $('.header .header-menu .menu-link-wraper a').click(function (e) {
        if ($(this).attr('href') != 'javascript:void(0);') {
            return;
        }
        if (winWidth < 992) {
            e.preventDefault();
            $('.js-menu-toggle').trigger('click');
        }
    })
}
function addFixedClass() {
    let scrollTopValue = $(window).scrollTop();
    if (!$(".header-menu-wraper").hasClass("open") && scrollTopValue > 0) {
        $('header').addClass('fixed');
    }
    else {
        $('header').removeClass('fixed');
    }
}
function dropdownBtnCustom() {
    $(".js-language-toggle").click(function () {
        $(".dropdown a").removeClass("clicked");
        $(".dropdown a").children("span").removeClass("clicked");
        $(".arrow").toggleClass("open");
        $(".dropdown").toggleClass("open");
    });

    $(".dropdown a").click(function () {
        $(".dropdown a").removeClass("clicked");
        $(".dropdown a").children("span").removeClass("clicked");
        $(this).toggleClass("clicked"); $(this).children("span").toggleClass("clicked");
    });
};

function countDownInit() {
    if (!$('.js-counter').get(0)) { return }
    updateCountDown();
    setInterval(function () {
        updateCountDown();

        // Countdown animation
        var dur = .5;
        var rip2X = 30;
        var rip1X = 10;
        var ripWidth = 15;
        if (winWidth < 992) {
            var ripWidth = 12;
        }
        if (winWidth < 768) {
            ripWidth = 7;
            var rip2X = 20;
            var rip1X = 7.5;
        }
        gsap.timeline().fromTo('.ripple--left.ripple--2', {
            width: 0,
            x: rip2X,
        }, {
            x: 0,
            width: ripWidth,
            duration: dur,
            ease: Power1.easeOut,
        }).to('.ripple--left.ripple--2', {
            x: -rip2X,
            width: 0,
            duration: dur,
            ease: Power1.easeIn,
        })

            .fromTo('.ripple--left.ripple--1', {
                width: 0,
                x: rip1X * 2,
            }, {
                x: 0,
                width: ripWidth,
                duration: dur,
                ease: Power1.easeOut,
            }, "-=" + dur * 2).to('.ripple--left.ripple--1', {
                x: -rip1X,
                width: 0,
                duration: dur,
                ease: Power1.easeIn,
            }, "-=" + dur);

        gsap.timeline().fromTo('.ripple--right.ripple--2', {
            width: 0,
            x: -rip2X,
        }, {
            x: 0,
            width: ripWidth,
            duration: dur,
            ease: Power1.easeOut,
        }).to('.ripple--right.ripple--2', {
            x: rip2X,
            width: 0,
            duration: dur,
            ease: Power1.easeIn,
        })

            .fromTo('.ripple--right.ripple--1', {
                width: 0,
                x: -(rip1X * 2),
            }, {
                x: 0,
                width: ripWidth,
                duration: dur,
                ease: Power1.easeOut,
            }, "-=" + dur * 2).to('.ripple--right.ripple--1', {
                x: rip1X,
                width: 0,
                duration: dur,
                ease: Power1.easeIn,
            }, "-=" + dur);
    }, 1000);
}

function updateCountDown() {
    var toDate = moment.tz(endDate, "Asia/Dubai").countdown();
    var daysRemaining = Math.floor(moment.duration(moment(endDate).diff(moment())).asDays()).toString();
    var days = addZeroBase(daysRemaining);
    var hours = addZeroBase(toDate.hours);
    var minutes = addZeroBase(toDate.minutes);
    var seconds = addZeroBase(toDate.seconds);

    putNumberInTag('.js-counter .counter--days .number', days);
    putNumberInTag('.js-counter .counter--hours .number', hours);
    putNumberInTag('.js-counter .counter--minutes .number', minutes);
    putNumberInTag('.js-counter .counter--seconds .number', seconds);
}

function addZeroBase(number) {
    if (!number) {
        return [0, 0];
    }
    var numberStr = number.toString();
    if (numberStr[1]) {
        return numberStr;
    } else {
        return '0' + numberStr[0];
    }
}

function putNumberInTag(target, number) {
    var numb1 = number[0].toString();
    var numb2 = number[1].toString();
    $(target).each(function () {
        var numbElems = $(this).find('i');
        if (numbElems[0].innerHTML.toString() != numb1) {
            numbElems[0].innerHTML = numb1;
            $(numbElems[0]).attr('class', 'is-' + numb1);
        }
        if (numbElems[1].innerHTML.toString() != numb2) {
            numbElems[1].innerHTML = numb2;
            $(numbElems[1]).attr('class', 'is-' + numb2);
        }
    });
}

function scrollToTopClickBind() {
    $('.js-to-top').click(function (e) {
        e.preventDefault();
        gsap.to(window, { duration: 1, scrollTo: 0 });
    });
}

function inPageLinkingInit() {
    $('[data-sec-link]').click(function (e) {
        if ($(this).attr('href') != 'javascript:void(0);') {
            return;
        }
        e.preventDefault();
        scrollToDiv($(this).attr('data-sec-link'))
    });

    setOnScrollPosition();
}

function setOnScrollPosition() {
    $(window).on('DOMContentLoaded load resize scroll', function () {
        var toActiveId = '';
        $('[data-sec-id]').each(function () {
            if (isElementInViewport(this)) {
                toActiveId = $(this).attr('data-sec-id');
            }
        });
        $('[data-sec-link]').parent().removeClass('active');
        $('[data-sec-link="' + toActiveId + '"]').parent().addClass('active');
        if (location.hash != toActiveId && toActiveId && toActiveId != '#') {
            location.hash = toActiveId;
        }
    });
}

function scrollToDiv(div) {
    gsap.to(window, {
        scrollTo: {
            y: '[data-sec-id="' + div + '"]',
            offsetY: $('.header').outerHeight(),
        },
        duration: 1,
    });
}

function isElementInViewport(ele) {
    const { top, bottom } = ele.getBoundingClientRect();
    const vHeight = (window.innerHeight || document.documentElement.clientHeight);

    return (
        (top > 0 || bottom > 0) &&
        top < vHeight
    );
}

function setVideoTag() {
    var videoId = $('.inject-video').attr('desktop-video-id');

    if (winWidth < 768) {
        videoId = $('.inject-video').attr('mobile-video-id');
    }
    var scriptTag1 = $('<script />', {
        src: '//fast.wistia.com/embed/medias/' + videoId + '.jsonp',
        async: true,
    });
    var scriptTag2 = $('<script />', {
        src: '//fast.wistia.com/assets/external/E-v1.js',
        async: true,
    });
    var divTag = $('<div />', {
        class: 'wistia_embed wistia_async_' + videoId,
        style: 'height:100%;width:100%',
    });
    $('.inject-video').append(scriptTag1, scriptTag2, divTag);

    window._wq = window._wq || [];
    _wq.push({
        id: videoId, onReady: function (video) {
            document.querySelector('.js-toggle-mute').addEventListener('click', function () {
                if (document.querySelector('.js-toggle-mute').classList.contains("has-audio")) {
                    document.querySelector('.js-toggle-mute').classList.remove('has-audio');
                    Wistia.api(videoId).mute();
                } else {
                    document.querySelector('.js-toggle-mute').classList.add('has-audio');
                    Wistia.api(videoId).unmute();
                }
            });
        }
    });
}


function swiper() {
    const swiper = new Swiper('.image-slider-section .swiper', {
        // Optional parameters
        loop: false,
        slidesPerView: 4,
        spaceBetween: 20,

        // If we need pagination
        pagination: {
            el: '.image-slider-section .swiper-pagination',
        },

        // Navigation arrows
        navigation: {
            nextEl: '.image-slider-section .swiper-button-next',
            prevEl: '.image-slider-section .swiper-button-prev',
        },

        // And if we need scrollbar
        scrollbar: {
            el: '.image-slider-section .swiper-scrollbar',
        },
        breakpoints: {
            320: {
                slidesPerView: 2,
                spaceBetween: 20,
            },
            768: {
                slidesPerView: 3,
                spaceBetween: 20,
            },
            1024: {
                slidesPerView: 4,
                spaceBetween: 20,
            },
        },
    });

};

function swiper2() {
    const swiper = new Swiper('.other-events-slider .swiper', {
        // Optional parameters
        direction: 'horizontal',
        loop: false,
        slidesPerView: 4,
        spaceBetween: 20,

        // If we need pagination
        pagination: {
            el: '.swiper-pagination',
        },

        // Navigation arrows
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },

        // And if we need scrollbar
        scrollbar: {
            el: '.swiper-scrollbar',
        },
        breakpoints: {
            320: {
                slidesPerView: 1,
                spaceBetween: 20,
            },
            768: {
                slidesPerView: 3,
                spaceBetween: 20,
            },
            1024: {
                slidesPerView: 4,
                spaceBetween: 20,
            },
        },
    });

};

function swiper3() {
    const swiper = new Swiper('.event-images-slider .swiper', {
        // Optional parameters
        direction: 'horizontal',
        slidesPerView: 6,
        spaceBetween: 20,
        loop: true,
        centeredSlides: true,

        // If we need pagination
        pagination: {
            el: '.swiper-pagination',
        },

        // Navigation arrows
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },

        // And if we need scrollbar
        scrollbar: {
            el: '.swiper-scrollbar',
            draggable: true,
        },
        breakpoints: {
            320: {
                slidesPerView: 2,
                spaceBetween: 20,
            },
            768: {
                slidesPerView: 3,
                spaceBetween: 20,
            },
            1024: {
                slidesPerView: 6,
                spaceBetween: 20,
            },
        },
    });

};

function accordian() {

    $('.faq-accordian-container h3').on('click', function () {
        $(this).toggleClass('active').siblings('h3').removeClass('active');
        //$(this).next('.answer-content').slideToggle().siblings('.answer-content').slideUp();
        $(this).parent('.faq-item').find('.answer-content').slideToggle();
        $(this).parent('.faq-item').siblings().find('.answer-content').slideUp();
    })

}

function videoSlider() {

    $('.thumb-list li a').click(function(){
        $('.thumb-list li a').removeClass('active');
        $(this).addClass('active');
        $('#main-video').html('<iframe type="text/html" src="http://www.youtube.com/embed/'+$(this).attr('href')+'" allowfullscreen frameborder="0"></iframe>');
        return false;
    });

}